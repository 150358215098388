import images from "../constants/images";
import {ServiceCaption} from "./Caption";
const Services = () => {
    return (
        <section className="services text-white  xl:h-[600px] flex flex-col md:flex-row gap-[10px] bg-yellow border-b-[10px] border-yellow">
            <div className="training flex flex-1 flex-col relative  justify-center items-center">
                <img src={images.trainingBG} alt='horse training' className="w-full h-full object-cover"/>
                <div className="transparentOverylay absolute top-0 w-full h-full bg-black opacity-25"></div>
                <ServiceCaption title='Horse training' content='Professional horse trianing' link='/training'/>
            </div>

            <div className="training flex flex-1 flex-col relative  justify-center items-center">
                <img src={images.breedingBG} alt='horse breeding' className="w-full h-full object-cover"/>
                <div className="transparentOverylay absolute top-0 w-full h-full bg-black opacity-25"></div>
                <ServiceCaption title='Horse breeding' content='Nurturing the next generation of champions' link='/breeding'/>
            </div>
            

            <div className="training flex flex-1 flex-col relative  justify-center items-center">
                <img src={images.salesBG} alt='horse breeding' className="w-full h-full object-cover"/>
                <div className="transparentOverylay absolute top-0 w-full h-full bg-black opacity-25"></div>
                <ServiceCaption title='Horse sales' content='Quality horses, exceptional companions' link='/sales'/>
            </div>


        </section>
    )
}
export default Services;