import images from "../constants/images";
import {Caption} from "./Caption";
const Farm = () => {
    return (
        <section className="farmWrapper flex justify-center relative border-b-[10px] border-yellow">
            <div className="myFarm max-w-screen-2xl z-10 text-[18px] font-LibreRegular text-white flex flex-col justify-center items-center md:items-start relative w-full h-[400px] lg:h-[700px]">
                <Caption title='My farm' content='Our farm, their bliss!' link='/farm' />
            </div>
            <img src={images.farmBG} alt='farm' className="w-full h-full object-cover absolute"/>
            <div className="transparentOverylay absolute top-0 w-full h-full bg-black opacity-25"></div>
        </section>
    )
}
export default Farm;