import {useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll", ()=>{
            if(window.scrollY>150){
                setBackToTopButton(true)
            } else{
                setBackToTopButton(false);
            }
        })
    }, [])

    const scrollUp = () =>{
        window.scrollTo({
            top:0,
            behavior: "smooth"
        })
    }
    return (
        <div>
            {backToTopButton && (
            <div onClick={scrollUp} className="scrollUp flex self-end cursor-pointer fixed z-30 bottom-8 right-0 sm:right-6">
                <button className="w-[3.5rem] h-[3.5rem] rounded-full bg-blue border-[1px] border-yellow flex flex-col items-center justify-center">
                    <FaArrowUp className="text-yellow w-[1.5rem] h-[1.5rem]"/>
                </button>
            </div>
        )}
        </div>
    
        )
}


export default BackToTopButton;