import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";

const Nav = () => {
  const [isX, setIsX] = useState('false');
  const buttonRef = useRef(null);
  function toggleOverlayMenuButton() {
    setIsX(!isX);
    const button = buttonRef.current;
    button.classList.toggle('open');
  }
  const [english, setEnglish] = useState(true);
  function toggleLanguageButton () {
    setEnglish(!english);
  }

  const [servicesMenu, setServicesMenu] = useState(false);
  function toggleServicesMenu () {
    setServicesMenu(!servicesMenu);
  }

  const servicesDesktopRef = useRef(null);
  
  const handleClickOutside = (e) => {
    if (!servicesDesktopRef.current?.contains(e.target)) {
      setServicesMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    }; 
  }, []);

//   const [activeTab, setActiveTab] = useState('');
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
// };
const currentRoute = window.location.pathname;

  return (
    <nav className='navWrapper bg-blue flex items-center justify-center flex-col border-b-[10px] border-yellow'>
    <div className='nav max-w-screen-2xl flex justify-between h-[80px] w-full text-white flex items-center px-[1rem] md:px-[2rem] xl:px-[4rem]'>
        <div className='navLogo order-2 md:order-1 text-[1.5rem] lg:text-[2rem] font-semibold'><a href="/home">AxStable</a></div>
        
        <div className='navList font-LibreRegular order-2 hidden md:flex '>
          <ul className='flex gap-[2rem] lg:gap-[3rem] text-[1rem] 2xl:text-[1.2rem]'>
            <NavLink to='/home' activeclassname="active"  className='hover:text-yellow'>Home</NavLink>
            <NavLink to='/farm' className='hover:text-yellow'>Our farm</NavLink>
            <div className='servicesWrapper flex flex-col items-center' ref={servicesDesktopRef}>
              <div className='flex items-center hover:text-yellow' onClick={()=>toggleServicesMenu()} >
                <li className={currentRoute === '/breeding' || currentRoute === '/training' || currentRoute === '/sales' ? 'active' : ''} >Services</li>
                <IoMdArrowDropdown />
              </div>
              { servicesMenu && (
                <div className='servicesMenu rounded-md bg-blue absolute top-[3.5rem] p-[1.5rem] z-20 '>
                  <ul className='text-[16px] flex flex-col gap-2'>
                    <NavLink to='/breeding' className='hover:text-yellow' >Breeding</NavLink>
                      <NavLink to='/training' className='hover:text-yellow' >Training</NavLink>
                      <NavLink to='/sales' className='hover:text-yellow'>Sales</NavLink>
                  </ul>
                </div>
            )
          }
            </div>
            <NavLink to='/horses' className=' focus:text-yellow hover:text-yellow'>Our horses</NavLink>
            <NavLink to='/about-me' className=' focus:text-yellow hover:text-yellow'>About me</NavLink>
            <NavLink to='/contact' className=' focus:text-yellow hover:text-yellow'>Contact</NavLink>
          </ul>
        </div>
          <div className='languageButton flex order-3 justify-center items-center border-[1px] w-[30px] cursor-pointer' onClick={()=>toggleLanguageButton()}>
            {english ? "EN" : "SV"}            
          </div>
          
        <div className='hamburgerButton flex flex-col order-1 md:hidden h-[24px] flex flex-col gap-[5px]' ref={buttonRef} onClick={()=>toggleOverlayMenuButton()}>
          <span className=' border-[1px] w-[24px] relative  duration-300'></span>
          <span className='border-[1px] w-[24px] relative  duration-300'></span>
          <span className=' border-[1px] w-[24px] relative duration-300'></span>
        </div>
    </div>
    {!isX && (
      <div className='overlayMenu w-full h-screen bg-blue  '>
        <ul className='flex font-LibreRegular flex-col gap-[2rem] py-[2rem] text-white text-[18px] px-[2rem] text-start'>
          <NavLink to='/home' className=' focus:text-yellow hover:text-yellow border-b-[1px] pb-4 border-white'>Home</NavLink>
          <NavLink to='/farm' className=' focus:text-yellow hover:text-yellow border-b-[1px] pb-4 border-white'> Our farm</NavLink>
          <div className='servicesWrapper' ref={servicesDesktopRef}>
            <div className='flex justify-between items-center w-full border-b-[1px] pb-4 border-white relative' onClick={()=>toggleServicesMenu()} >
              <li className={currentRoute === '/breeding' || currentRoute === '/training' || currentRoute === '/sales' ? 'active' : ''} >Services</li>
              <IoMdArrowDropdown />
            </div>
              { servicesMenu && (
                  <div className='servicesMenu bg-blue'>
                    <ul className='flex flex-col gap-[2rem] pt-[2rem] pl-[2rem]'>
                      <NavLink to='/breeding' className='hover:text-yellow border-b-[1px] pb-4 border-white'>Breeding</NavLink>
                      <NavLink to='/training' className='hover:text-yellow border-b-[1px] pb-4 border-white'>Training</NavLink>
                      <NavLink to='/sales' className='hover:text-yellow border-b-[1px] pb-4 border-white'>Sales</NavLink>
                    </ul>
                  </div>
              )
            }
          </div>
          <NavLink to='/horses' className=' focus:text-yellow  border-b-[1px] pb-4 border-white'>Our horses</NavLink>
          <NavLink to='/about-me' className=' focus:text-yellow  border-b-[1px] pb-4 border-white'>About me</NavLink>
          <NavLink to='/contact' className=' focus:text-yellow '>Contact</NavLink>
        </ul>

      </div>
    )}
    </nav>

    
  )
}

export default Nav