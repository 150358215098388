import images from "./images";
export const farmSlides = [
    {
        id:1,
        image: images.farm1
    },
    {
        id:2,
        image: images.farm2
    },
    {
        id:3,
        image: images.farm3
    },
    {
        id:11,
        image: images.farm1
    },
    {
        id:22,
        image: images.farm2
    },
    {
        id:33,
        image: images.farm3
    },
    {
        id:111,
        image: images.farm1
    },
    {
        id:222,
        image: images.farm2
    },
    {
        id:333,
        image: images.farm3
    },
]
export const aboutSlides = [
    {
        id:1,
        image: images.about1
    },
    {
        id:2,
        image: images.about2
    },
    {
        id:3,
        image: images.about3
    },
    {
        id:11,
        image: images.about1
    },
    {
        id:22,
        image: images.about2
    },
    {
        id:33,
        image: images.about3
    },
    {
        id:111,
        image: images.about1
    },
    {
        id:222,
        image: images.about2
    },
    {
        id:333,
        image: images.about3
    },
]
export const horsesSlides = [
    {
        id:1,
        image: images.horse1,
        link: '/individual'
    },
    {
        id:2,
        image: images.horse2,
        link: '/individual'
    },
    {
        id:3,
        image: images.horse3,
        link: '/individual'
    },
    {
        id:11,
        image: images.horse1,
        link: '/individual'
    },
    {
        id:22,
        image: images.horse2,
        link: '/individual'
    },
    {
        id:33,
        image: images.horse3,
        link: '/individual'
    },
    {
        id:111,
        image: images.horse1,
        link: '/individual'
    },
    {
        id:222,
        image: images.horse2,
        link: '/individual'
    },
    {
        id:333,
        image: images.horse3,
        link: '/individual'
    },
]
export const trainingSlides = [
    {
        id:1,
        image: images.training1
    },
    {
        id:2,
        image: images.training2
    },
    {
        id:3,
        image: images.training3
    },
    {
        id:11,
        image: images.training1
    },
    {
        id:22,
        image: images.training2
    },
    {
        id:33,
        image: images.training3
    },
    {
        id:111,
        image: images.training1
    },
    {
        id:222,
        image: images.training2
    },
    {
        id:333,
        image: images.training3
    },
]
export const breedingSlides = [
    {
        id:1,
        image: images.breeding1
    },
    {
        id:2,
        image: images.breeding2
    },
    {
        id:3,
        image: images.breeding3
    },
    {
        id:11,
        image: images.breeding1
    },
    {
        id:22,
        image: images.breeding2
    },
    {
        id:33,
        image: images.breeding3
    },
    {
        id:111,
        image: images.breeding1
    },
    {
        id:222,
        image: images.breeding2
    },
    {
        id:333,
        image: images.breeding3
    },
]