const Introduction = ({contentLeft, contentRight}) => {
    return (
        <div className="wrapper flex justify-center">
            <div className="content flex-wrap px-[1rem] md:px-0 md:w-[70%] gap-[2rem] md:gap-[10%] flex flex-col md:flex-row py-[2rem] md:py-[4rem]">
                <div className="content-left flex flex-1 ">
                    {contentLeft}
                </div>
                <div className="content-left flex flex-1 ">
                    {contentRight}
                </div>
                

            </div>

        </div>
    )
}
export default Introduction;