import images from "../constants/images";
import { Caption } from "./Caption";
const About = () => {
    return (
        <section className="aboutMeWrapper flex justify-center relative border-b-[10px] border-yellow">
            <div className="myFarm max-w-screen-2xl  z-10 text-[18px] font-LibreRegular text-white flex flex-col justify-center items-center md:items-start relative w-full h-[400px] lg:h-[700px]">
            <Caption title='About me' content='Passionate equestrian, devoted to horses!' link='/about-me' />
            </div>
            <img src={images.aboutBG} alt='about me' className="w-full h-full object-cover absolute"/>
            <div className="transparentOverylay absolute top-0 w-full h-full bg-black opacity-25"></div>
        </section>
        
    )
}
export default About;

