import Nav from "../components/Nav";
import Header from "../components/Header";
import images from "../constants/images";
import HorseCards from "../components/HorseCards";
import Footer from "../components/Footer";
import BackToTopButton from "../components/BackToTopButton";
const Salespage = () => {
    return (
        <>
            <Nav />
            <Header image={images.salesHeader} title='Sale'/>
            <HorseCards />
            <Footer />
            <BackToTopButton />
        </>
    )
}
export default Salespage;