import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './Pages/Homepage';
import Farmpage from './Pages/Farmpage';
import Aboutpage from './Pages/Aboutpage';
import Horsespage from './Pages/Horsespage';
import Contactpage from './Pages/Contactpage';
import Trainingpage from './Pages/Trainingpage';
import Breedingpage from './Pages/Breedingpage';
import Salespage from './Pages/Salespage';
import Individual from './Pages/Individual';
import ScrollFromTop from './components/ScrollFromTop';
const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollFromTop />
        <Routes>
          <Route index element={<Homepage />}/>
          <Route path='/home' element={<Homepage />}/>
          <Route path='/farm' element={<Farmpage />}/>
          <Route path='/about-me' element={<Aboutpage />}/>
          <Route path='/horses' element={<Horsespage />}/>
          <Route path='/contact' element={<Contactpage />}/>
          <Route path='/breeding' element={<Breedingpage />}/>
          <Route path='/training' element={<Trainingpage />}/>
          <Route path='/sales' element={<Salespage />}/>
          <Route path='/individual' element={<Individual />}/>

        </Routes>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
