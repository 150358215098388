const Header = ({image, title}) => {
    return (
        <div className="h-[15rem] md:h-[20rem] w-full relative flex justify-center items-center">
            <img  src={image} alt="farm" load="lazy" className='w-full h-full object-cover'/>
            <div className="transparentOverylay absolute top-0 w-full h-full bg-black opacity-25"></div>
            <h1 className="farm text-[2rem] xl:text-[2.5rem] font-LibreBold  absolute text-white">
                {title}
            </h1>
        </div>
    )
}
export default Header;