import Nav from "../components/Nav";
import Hero from "../components/Hero";
import Farm from "../components/Farm";
import Services from "../components/Services";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import BackToTopButton from "../components/BackToTopButton";
const Homepage = () => {
    return (
        <>
            <div className='fixed w-full top-0 z-20'>
                <Nav />
            </div>
            <Hero />
            <Farm />
            <Services id='services'/>
            <About />
            <Contact />
            <Footer />
            <BackToTopButton />
        </>
    )
}

export default Homepage;