import Nav from "../components/Nav";
import images from "../constants/images";
import Footer from "../components/Footer";
import {Slider} from "../components/Slider";
import { breedingSlides } from "../constants/data";
import { useState } from "react";
import BackToTopButton from "../components/BackToTopButton";
const Individual = () => {
    const [tab, setTab] = useState('performance');
    const [activeTab, setActiveTab] = useState('performance')
    const handleClick = (tab) => {
        setTab(tab);
        setActiveTab(tab);
    }
    
    return (
        <>
        <div className='sticky w-full top-0 z-20'>
        <Nav />
        </div>
        <section className="horseProfile-image bg-blue overflow-hidden flex flex-col items-center">
            <img src={images.profile1} alt='horse' loading="lazy" className='w-full h-[35rem] object-cover'/>
            <h2 className="text-[1.5rem] md:text-[2.5rem] font-LibreBold text-yellow py-[2rem]">HORSENAME</h2>
            <p className="max-w-screen-2xl px-[1rem] md:px-[2rem] lg:px-[6rem] xl:px-[12rem] 2xl:px-0  text-justify text-white text-[1rem] font-LibreRegular leading-relaxed ">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi labore atque vitae eius minus voluptas reiciendis accusantium a fugit, magni illo neque assumenda consequuntur officia recusandae ipsa dolore in quae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus soluta maxime asperiores blanditiis quidem labore ipsum dicta qui commodi cumque optio, fugiat consequuntur libero repudiandae consectetur esse exercitationem magni at.</p>
            <div className="flex flex-col items-center py-[4rem] px-[1rem] md:px-[2rem] lg:px-[6rem] xl:px-[12rem] text-justify text-white text-[1rem] font-LibreRegular leading-relaxed ">
                <ul className="flex flex-col md:flex-row w-[90%] justify-between md:px-[9rem]">
                    <li className={`${activeTab==='performance'? 'active1': '' } border-b-[1px] border-yellow pb-4 md:py-0 md:border-none cursor-pointer hover:text-yellow`} onClick={()=>handleClick('performance')}>PERFORMANCE</li>
                    <li className={`${activeTab==='pedigree'? 'active1': '' } border-b-[1px] border-yellow py-4 md:py-0  md:border-none cursor-pointer hover:text-yellow`} onClick={()=>handleClick('pedigree')}>PEDIGREE</li>
                    {/* <li className={`${activeTab==='progeny'? 'active1': '' } border-b-[1px] border-yellow py-4 md:py-0  md:border-none cursor-pointer hover:text-yellow`} onClick={()=>handleClick('progeny')}>PROGENY</li>
                    <li className={`${activeTab==='recommendations'? 'active1': '' } border-b-[1px] border-yellow py-4 md:py-0  md:border-none cursor-pointer hover:text-yellow`} onClick={()=>handleClick('recommendations')}>BREEDING RECOMMENDATIONS</li> */}
                </ul>
                <div className="divider w-full md:h-[2px] bg-yellow mt-[2rem] mb-[3rem]"></div>
                <div className="max-w-screen-2xl information-card w-full md:h-[20rem] bg-yellow shadow-inner rounded-xl">
                {(tab==='performance' &&
                    <p className="p-[2rem] text-black text-[1rem] font-LibreRegular leading-relaxed ">
                       Perforamnce Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi labore atque vitae eius minus voluptas reiciendis accusantium a fugit, magni illo neque assumenda consequuntur officia recusandae ipsa dolore in quae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus soluta maxime asperiores blanditiis quidem labore ipsum dicta qui commodi cumque optio, fugiat consequuntur libero repudiandae consectetur esse exercitationem magni at.
                       Perforamnce Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi labore atque vitae eius minus voluptas reiciendis accusantium a fugit, magni illo neque assumenda consequuntur officia recusandae ipsa dolore in quae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus soluta maxime asperiores blanditiis quidem labore ipsum dicta qui commodi cumque optio, fugiat consequuntur libero repudiandae consectetur esse exercitationem magni at.
                    </p>) || 
                (tab==='pedigree' &&
                    <p className="p-[2rem] text-black text-[1rem] font-LibreRegular leading-relaxed ">
                       Pedigree Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi labore atque vitae eius minus voluptas reiciendis accusantium a fugit, magni illo neque assumenda consequuntur officia recusandae ipsa dolore in quae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus soluta maxime asperiores blanditiis quidem labore ipsum dicta qui commodi cumque optio, fugiat consequuntur libero repudiandae consectetur esse exercitationem magni at.
                    </p>) 
                // (tab==='progeny' &&
                //     <p className="p-[2rem] text-black text-[1rem] font-LibreRegular leading-relaxed ">
                //        Progeny Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi labore atque vitae eius minus voluptas reiciendis accusantium a fugit, magni illo neque assumenda consequuntur officia recusandae ipsa dolore in quae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus soluta maxime asperiores blanditiis quidem labore ipsum dicta qui commodi cumque optio, fugiat consequuntur libero repudiandae consectetur esse exercitationem magni at.
                //     </p>) || 
                // (tab==='recommendations' &&
                //     <p className="p-[2rem] text-black text-[1rem] font-LibreRegular leading-relaxed ">
                //        Recommendations Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi labore atque vitae eius minus voluptas reiciendis accusantium a fugit, magni illo neque assumenda consequuntur officia recusandae ipsa dolore in quae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus soluta maxime asperiores blanditiis quidem labore ipsum dicta qui commodi cumque optio, fugiat consequuntur libero repudiandae consectetur esse exercitationem magni at.
                //     </p>)

                }
                </div>
            </div>
        </section>
        <Slider slides={breedingSlides}/>
        <Footer />
        <BackToTopButton />
        </>
    )
}
export default Individual;