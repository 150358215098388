import {  useLocation } from "react-router-dom";
import { useEffect } from "react";
const ScrollFromTop = () => {
    const location = useLocation();

    useEffect (()=> {
        window.scrollTo(0, 0)
    }, [location.pathname])
     return null
}
export default ScrollFromTop