import images from "../constants/images";
import { NavLink } from "react-router-dom";
const horseDatas = [
    {
        id:1,
        image: images.horse1,
        name: 'HORSENAME',
        introduction: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, minima? Animi saepe sint ipsa, odit aspernatur reprehenderit reiciendis fugiat unde neque quos, omnis sed debitis blanditiis cumque? Esse, veritatis odio!"
    },
    {
        id:2,
        image: images.horse2,
        name: 'HORSENAME',
        introduction: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, minima? Animi saepe sint ipsa, odit aspernatur reprehenderit reiciendis fugiat unde neque quos, omnis sed debitis blanditiis cumque? Esse, veritatis odio!"
    },
    {
        id:3,
        image: images.horse3,
        name: 'HORSENAME',
        introduction: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, minima? Animi saepe sint ipsa, odit aspernatur reprehenderit reiciendis fugiat unde neque quos, omnis sed debitis blanditiis cumque? Esse, veritatis odio!"
    },
]
const HorseCards = () => {
    
    return (
        < div className="bg-blue p-[1rem] md:p-[2rem] flex flex-col gap-[2rem] border-b-[10px] border-yellow">
            {horseDatas.map((data)=>(
            <div key={data.id} className="salesCard flex flex-col  md:flex-row ">
                <div className="card-image bg-yellow w-full md:w-1/3 ">
                    <img src={data.image} alt='horse1' loading="lazy" className="w-full h-[20rem] md:h-[25rem] object-cover "/>
                </div>
                <div className="card-text w-full bg-white md:w-2/3 px-[1rem] md:px-[4rem] lg:px-[6rem] py-[2rem] md:py-[4rem] text-[1rem] font-LibreRegular flex flex-col gap-[1rem] justify-start items-start">
                    <h2 className="font-LibreBold text-[1rem]">{data.name}</h2>
                    <p className="text-justify">{data.introduction}</p>
                    <NavLink to='/individual'><button className="rounded-sm px-3 py-1 mt-[1rem] cursor-pointer text-yellow bg-blue hover:text-white">Read more</button></NavLink>
                </div>
            </div>
            ))}
        </div>
    )

}
export default HorseCards;