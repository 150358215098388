import Nav from "../components/Nav";
import Header from "../components/Header";
import images from "../constants/images";
import Introduction from "../components/Introduction";
import {Slider} from "../components/Slider";
import Footer from "../components/Footer";
import { breedingSlides } from "../constants/data";
import BackToTopButton from "../components/BackToTopButton";
const Breedingpage = () => {
    return (
        <>
            <div className='sticky w-full top-0 z-20'>
                <Nav />
            </div>
            <Header image={images.breedingHeader} title='Breeding'/>
            <Introduction 
            contentLeft={
            <div className="flex flex-col gap-[1rem]">
                <p className="text-justify text-[1rem] font-LibreRegular">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores soluta distinctio laborum, doloribus omnis facilis repellat et numquam excepturi, tempora iure fuga modi illum rem provident ipsam, minus laboriosam quasi.</p>
                <p className="text-justify text-[1rem] font-LibreRegular">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores soluta distinctio laborum, doloribus omnis facilis repellat et numquam excepturi, tempora iure fuga modi illum rem provident ipsam, minus laboriosam quasi.</p>
            </div>
            }
            contentRight={
            <div className="flex flex-col gap-[1rem]">
                <p className="text-justify text-[1rem] font-LibreRegular">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores soluta distinctio laborum, doloribus omnis facilis repellat et numquam excepturi, tempora iure fuga modi illum rem provident ipsam, minus laboriosam quasi.</p>
                <p className="text-justify text-[1rem] font-LibreRegular">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores soluta distinctio laborum, doloribus omnis facilis repellat et numquam excepturi, tempora iure fuga modi illum rem provident ipsam, minus laboriosam quasi.</p>
            </div>
            }
            />
            <Slider slides={breedingSlides}/>
            <Footer />
            <BackToTopButton />
        </>
    )
}
export default Breedingpage;