import { NavLink } from "react-router-dom";
export const Caption = ({title, content, link}) => {
    return (
        <div className="overlayText absolute px-[2rem] lg:px-[6rem]">
            <h1 className="text-[2rem] xl:text-[2.5rem] font-LibreBold ">{title}</h1>
            <p className="my-[1rem] text-[1.2rem] xl:text-[1.5rem] max-w-[18rem] leading-relaxed">{content}</p>
            <NavLink to={link}>
                <button className="rounded-sm px-3 py-1 bg-gradient-to-r from-[#FCE09A] from-10% to-[#96855C] hover:from-[#96855C] hover:to-[#FCE09A] cursor-pointer text-black hover:text-blue">Learn more</button>
            </NavLink>
        </div>
    )
    
}

export const ServiceCaption = ({title, content, link}) => {
    return (
            <div className="overlayText absolute flex flex-col md:h-[180px] max-w-[250px] lg:max-w-[300px]">
                <div className=" flex-1">
                    <h1 className="text-[2rem] font-LibreBold ">{title}</h1>
                    <p className="my-[1rem] text-[1.2rem] xl:text-[1.4rem]">{content}</p>
                </div>
                <NavLink to={link}>
                    <button className="flex-1 rounded-sm px-3 py-1 bg-gradient-to-r from-[#FCE09A] from-10% to-[#96855C] hover:from-[#96855C] hover:to-[#FCE09A] cursor-pointer text-black hover:text-blue">Learn more</button>
                </NavLink>
            </div>


            
    )
    
}

