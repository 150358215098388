import { NavLink } from "react-router-dom";
export const Slider = ( {slides} ) =>{
    return (
        <div className="sliderWrapper w-full overflow-hidden p-[10px] bg-yellow">
        <div className="sliderTrack w-[calc(450px*9)] h-[15rem] md:h-[20rem] flex gap-[10px] items-center bg-yellow relative animate-infinite-scroll pause-on-hover resume-on-hover">
                {slides.map((slide) => (
                    <div className="slide flex h-[15rem] md:h-[20rem] overflow-hidden w-[350px] md:w-[450px] hover:perspective " key={slide.id}>
                        <img src={slide.image} alt='farm' className="w-full object-cover transform hover:scale-125 duration-1000"/>
                    </div>
                ) )}
        </div>
        </div>
    )
}
export const HorseSlider = ( {slides} ) =>{
    return (
        <div className="sliderWrapper w-full overflow-hidden p-[10px] bg-yellow">
        <div className="sliderTrack w-[calc(450px*9)] h-[15rem] md:h-[20rem] flex gap-[10px] items-center bg-yellow relative animate-infinite-scroll pause-on-hover resume-on-hover">
                {slides.map((slide) => (
                    <div className="slide flex h-[15rem] md:h-[20rem] overflow-hidden  w-[450px] hover:perspective " key={slide.id}>
                        <NavLink to={slide.link}><img src={slide.image} alt='farm' className="w-full object-cover transform hover:scale-125 duration-1000"/></NavLink>
                    </div>
                ) )}
        </div>
        </div>
    )
}

