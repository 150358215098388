import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import BackToTopButton from "../components/BackToTopButton";
const Contactpage = () => {
    return (
        <>
            <Nav />
            <div className="contact w-full py-[2rem] lg:py-[4rem] px-[1rem] md:px-[4rem] px-[10rem] text-[1rem] 2xl:text-[1.2rem] font-LibreRegular">
                <div className="contact-top flex gap-[2rem] flex-col lg:flex-row">
                    <div className="contact-form flex-1">
                        <h1 className="text-[2rem] xl:text-[2.5rem] text-left font-LibreRegular pb-[1rem]">Contact us</h1>
                        <p className="text-justify pb-[2rem] leading-relaxed">We’d love to hear from you! If you have questions about Aaxtelius training, breeding, or sales programs, or would like more information about how we can help you purchase your next, upper-level KWPN prospect, please drop us a line below.</p>
                        <form className="flex flex-col w-full items-start gap-[1.5rem] ">
                            <div className="flex flex-col md:flex-row w-full gap-[2rem]">
                                <div className="flex flex-col w-full items-start gap-[8px]">
                                    <label>Name</label>
                                    <input type='text' name='name' className="bg-yellow w-full h-[2.5rem] rounded-sm shadow-md"></input>
                                </div>
                                <div className="flex flex-col w-full items-start gap-[8px]">
                                    <label>Company's name</label>
                                    <input type='text' name='company' className="bg-yellow w-full h-[2.5rem] rounded-sm shadow-md"></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full items-start gap-[8px]">
                                <label>Email</label>
                                <input type='text' name='email' className="bg-yellow w-full h-[2.5rem] rounded-sm shadow-md"></input>
                            </div>
                            <div className="flex flex-col w-full items-start gap-[8px]">
                                <label>Message</label>
                                <input type='text' name='message' className="bg-yellow w-full h-[5rem] rounded-sm  shadow-md"></input>
                            </div>
                            <button type='submit' className="bg-blue text-yellow py-[0.5rem] px-[2rem] rounded-sm shadow">Send</button>
                        </form>
                    </div>
                    <div className="map lg:flex-1 xl:ml-[9rem] overflow-hidden h-[400px] lg:h-auto">
                        <div className="w-full h-full object-cover">
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2247.6175801891773!2d12.567294376716577!3d55.713018795054914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653ad0c8c13b451%3A0xcca4ffa60c49a213!2sToo%20Good%20To%20Go%20ApS!5e0!3m2!1sen!2sdk!4v1716474641261!5m2!1sen!2sdk" width="100%" height="100%" style={{border:0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div className="contact-bottom flex flex-col md:flex-row pt-[2rem] lg:pt-[4rem] gap-[2rem] xl:gap-[9rem] text-start "> 
                    <div className="telephone">
                        <FaPhoneSquareAlt className="text-[25px] cursor-pointer hover:text-yellow"/>
                        <p className="cursor-pointer hover:text-yellow">Telephone: +45 00000000</p>
                    </div>
                    <div className="email text-start">
                        <MdMail className="text-[25px] cursor-pointer hover:text-yellow"/>
                        <p className="cursor-pointer hover:text-yellow">Email: amanda@gmail.com</p>
                    </div>
                    <div className="address  text-start">
                    <FaLocationDot className="text-[25px] cursor-pointer hover:text-yellow"/>
                        <p className="cursor-pointer hover:text-yellow">AxStable, Sweden</p>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopButton />
        </>
    )
}
export default Contactpage;