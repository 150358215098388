import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

const Contact = () => {
    return (
        <div className="contactWrapper flex items-center justify-center md:h-[18rem] border-b-[10px] border-yellow bg-gradient-to-r from-[#020724] via-blue to-[#52566C] text-[1rem] 2xl:text-[1.2rem] font-LibreRegular text-white ">
        <div className="contact max-w-screen-2xl flex flex-col  md:flex-row gap-[4rem] p-[2rem] lg:px-[6rem] w-full ">
            <div className="flex flex-col items-start flex-2 gap-[2rem]">
                <h1 className="text-[2rem] xl:text-[2.5rem] font-LibreBold">Contact us</h1>
                <p className="text-start">If you have any questions about our services, please feel free to contact us. </p>
                <div className="flex gap-[2rem] text-[25px]">
                <FaInstagramSquare className="cursor-pointer hover:text-yellow"/>
                <FaFacebookSquare className="cursor-pointer hover:text-yellow"/>
                <FaWhatsappSquare className="cursor-pointer hover:text-yellow"/>
                <GrMail className="cursor-pointer hover:text-yellow"/>
                </div>
            </div>
            <div className="contact w-full flex flex-col flex-1 md:items-end ">
                <div className="flex flex-col gap-[1rem] xl:gap-[2rem] text-start">
                    <div className="telephone ">
                        <p>Call us</p>
                        <p className="cursor-pointer hover:text-yellow">Telephone: +45 00000000</p>
                    </div>
                    <div className="email text-start">
                        <p>Send an email</p>
                        <p className="cursor-pointer hover:text-yellow">Email: amanda@gmail.com</p>
                    </div>
                    <div className="address  text-start">
                        <p>Our Address</p>
                        <p className="cursor-pointer hover:text-yellow">AxStable, Sweden</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
export default Contact;