import React from 'react';
import images from '../constants/images';
const Hero = () => {
  return (
    <section className='h-[600px] lg:h-screen border-b-[10px] border-yellow'>
        <img src={images.hero} alt='hero' className='flex w-full h-full object-cover '/>
    </section>
  )
}

export default Hero