import hero from '../images/hero.webp';
import farmBG from '../images/farmBG.webp';
import breedingBG from '../images/breedingBG.webp';
import trainingBG from '../images/trainingBG.webp';
import salesBG from '../images/salesBG.webp';
import aboutBG from '../images/aboutBG.webp'
import farmHeader from '../images/farmHeader.webp';
import farm1 from '../images/farm1.webp';
import farm2 from '../images/farm2.webp';
import farm3 from '../images/farm3.webp';
import aboutHeader from '../images/aboutHeader.webp'
import about1 from '../images/about1.webp';
import about2 from '../images/about2.webp';
import about3 from '../images/about3.webp';
import horsesHeader from '../images/horsesHeader.webp';
import horse1 from '../images/horse1.webp';
import horse2 from '../images/horse2.webp';
import horse3 from '../images/horse3.webp';
import trainingHeader from '../images/trainingHeader.webp';
import training1 from '../images/training1.webp';
import training2 from '../images/training2.webp';
import training3 from '../images/training3.webp';
import breedingHeader from '../images/breedingHeader.webp';
import breeding1 from '../images/breeding1.webp';
import breeding2 from '../images/breeding2.webp';
import breeding3 from '../images/breeding3.webp';
import salesHeader from '../images/salesHeader.webp'
import profile1 from '../images/profile1.webp'
const images = {
    hero,
    farmBG,
    breedingBG,
    trainingBG,
    salesBG,
    aboutBG,
    farmHeader,
    farm1,
    farm2,
    farm3,
    aboutHeader,
    about1,
    about2,
    about3,
    horsesHeader,
    horse1,
    horse2,
    horse3,
    trainingHeader,
    training1,
    training2,
    training3,
    breedingHeader,
    breeding1,
    breeding2,
    breeding3,
    salesHeader,
    profile1
}

export default images;